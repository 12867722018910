'use strict';
import './structure/adjustVh';
import './structure/adjustViewport';
import NavEvent from './components/NavEvent';
// import Rellax from 'rellax';
import InViewEvent from './components/inViewEvent';
// import SmoothScroll from 'smooth-scroll';
// import { lock, unlock } from 'tua-body-scroll-lock';
document.addEventListener('DOMContentLoaded', () => {
  // console.log('HelloWorld Morgen-Ponte');
  const navEvent = new NavEvent();
  const inViewEvent = new InViewEvent();

  const topKvTypos = document.querySelectorAll('.topPage__KVTypo');
  if (!topKvTypos) {
    return false;
  }
  for (var i = 0; i < topKvTypos.length; i++) {
    topKvTypos[i].classList.add('Act');
  }
  const topKv = document.querySelector('.topPage__KVBg');
  if (topKv) {
    window.addEventListener('scroll', () => {
      let posY = window.scrollY;
      topKv.style.backgroundPosition = 'center bottom ' + -(posY / 10) + 'px';
      // topKv.style.transform = 'translateY(' + posY / 10 + 'px)';
      // topKv.style.backgroundSize = '120%';
    });
  }
});
