import inView from 'in-view';
// import SmoothScroll from 'smooth-scroll';

export default class InViewEvent {
  constructor() {
    this.init();
  }

  init() {
    // inView.threshold(0.2);
    inView('.js-inView')
      .on('enter', function (el) {
        el.classList.add('js-inViewAct');
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewAct');
      });
  }
}
