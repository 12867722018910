// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { lock, unlock } from 'tua-body-scroll-lock';

export default class NavEvent {
  constructor() {
    this.init();
  }
  init() {
    const toggles = document.querySelector('.js-NavToggle');
    const Nav = document.querySelector('.js-NavContent');
    // const smoothNav = document.querySelectorAll('.js-Smooth');
    toggles.addEventListener('click', (e) => {
      if (Nav.classList.contains('Act')) {
        unlock();
      } else {
        lock();
      }
      Nav.classList.toggle('Act');
      toggles.classList.toggle('Act');
    });
  }
}
